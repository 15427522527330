<template>
  <div>
    <h1 class="h3 mb-4 text-gray-800">All Projects</h1>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th>Organization.</th>
              <th>Region</th>
              <th>Status</th>
              <th>Approved</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="projects.length == 0">
              <td colspan="6">There is no data to display.</td>
            </tr>
            <tr v-for="(project, i) in projects" :key="`project-${i}`">
              <td width="60px">
                <img :src="project.logo" :alt="project.name" style="width: 60px">
              </td>
              <td>{{ project.name }}</td>
              <td>{{ project.organization }}</td>
              <td>{{ project.region }}</td>
              <td>{{ project.status }}</td>
              <td>{{ project.approved ? 'Yes' : 'No' }}</td>
              <td>
                <router-link :to="{ name: 'admin.projects.show', params: { id: project.id }}">Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      projects: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/admin/projects').then(response => {
        this.projects = response.data.projects
        this.loading = false
      })
    }
  }
}
</script>